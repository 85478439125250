.header {
    .left {
        float: left;
        display: flex;
        height: 100%; 
        align-items: flex-end;
        div {
            height: 46px;
            display: inline-block;
            line-height: 46px;
            padding: 0 30px;
            a {
                color: #AAADBB;
                i {
                    margin-right: 5px;
                }
            }
        }
        .route {
            border-top: 4px solid #ff5b18;
            background: #fff;
            a {
                color: #666;
            }
        }
    }
    .right {
        float: right;
        line-height: 55px;
        border-bottom: none;
    }
}