.page {
    background: #d2d6de;
}
.login {
    height: 100vh;
    padding-top: 50px;
}
.form {
    max-width: 300px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
}