@charset "UTF-8";
body {
  font-family: 'Microsoft Yahei', '微软雅黑', 'simsun', Arial;
}
body {
  margin: 0 auto;
  padding: 0;
}
/* chrome滚动条样式 */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: rgba(222, 222, 222, 0.5);
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}
::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-track-piece {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  width: 8px;
  min-height: 15px;
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.6);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.8);
}
.static {
  font-family: Helvetica Neue, Hiragino Sans GB, Microsoft Yahei, WenQuanYi Micro Hei, sans-serif;
  /* 头部 开始 */
  /* title区 */
  /* banner区 */
  /* detail区 */
  /* detail wrap-b */
  /* 友情链接 */
  /* 底部footer */
}
.static div,
.static h1,
.static h2,
.static h3,
.static h4,
.static ul,
.static li,
.static p,
.static dl,
.static dt,
.static dd,
.static a,
.static span,
.static table,
.static tr,
.static td {
  margin: 0 auto;
  padding: 0;
}
.static a {
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
.static ul {
  list-style: none;
}
.static img {
  border: none;
  height: auto;
}
.static button {
  border: none;
  cursor: pointer;
}
.static h1,
.static h2,
.static h3,
.static h4 {
  font-weight: normal;
}
.static header {
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}
.static .logo {
  width: 200px;
}
.static .logo img {
  width: 100%;
}
.static .header-wrap {
  width: 1200px;
  height: 72px;
  display: grid;
  grid-template-columns: 20% 50% 30%;
  grid-template-rows: 72px;
  align-items: center;
  margin: 0 auto;
}
.static .header-nav {
  display: flex;
  width: 100%;
  height: 72px;
  margin-left: 100px;
}
.static .header-nav a {
  display: block;
  color: #7c808c;
  line-height: 70px;
}
.static .header-nav a:hover {
  color: #ff5b18;
}
.static .header-nav li {
  padding: 0 7px;
  margin: 0 12px;
  font-size: 16px;
}
.static .header-nav li:hover {
  border-bottom: 2px solid #ff5b18;
}
.static .btn-top {
  display: inline-block;
  width: 114px;
  height: 40px;
  color: #fff;
  border: 1px solid #ff5b18;
  font-size: 14px;
  line-height: 40px;
  background-color: #ff5b18;
  border-radius: 2px;
  text-align: center;
}
.static .btn-top:hover {
  border-color: #ffd2a4;
  background-color: #ffd2a4;
  color: #ffffff;
}
.static #title {
  text-align: center;
}
.static #title h1 {
  font-size: 42px;
}
.static #title p {
  margin-top: 30px;
  font-size: 16px;
  color: #aaaaaa;
  letter-spacing: 2px;
}
.static .banner {
  width: 100%;
  height: 760px;
  position: relative;
  overflow: hidden;
  background-image: url("https://shared-https.ydstatic.com/market/ynote-website/bg-banner.jpg");
  background-size: cover;
}
.static .banner-box {
  width: 1200px;
  position: relative;
}
.static .banner-part {
  width: 671px;
  height: 519px;
  position: absolute;
  top: 165px;
  left: 0;
}
.static .banner-part .tabs {
  height: 41px;
  margin-top: 70px;
}
.static .banner-part .tabs > div {
  height: 41px;
  line-height: 41px;
  display: inline-block;
  box-sizing: border-box;
  background-color: #fff;
  padding: 0 30px;
  cursor: pointer;
}
.static .banner-part .tabs > div:first-child {
  border-right: 1px solid #7c808c;
}
.static .banner-part .tabs .checked {
  border-top: 2px solid #ff5b18;
}
.static .banner-part .show {
  margin-top: 10px;
}
.static .banner-part .wrap-show {
  width: 500px;
  margin: 0;
  opacity: .5;
  background-color: black;
}
.static .banner-part .item-show {
  width: 125px;
  display: inline-block;
  padding: 20px 20px;
  color: #fff;
}
.static .banner-part .item-show p {
  text-align: center;
}
.static .banner-part .item-show p:first-child {
  font-size: 16px;
}
.static .banner-part .ant-btn {
  background-color: #ff5b18;
}
.static .banner-right {
  position: absolute;
  width: auto;
  max-width: 600px;
  top: 215px;
  right: 50px;
}
.static .banner-right h1 {
  line-height: 73px;
  font-size: 52px;
  font-weight: 550;
}
.static .banner-right h2 {
  line-height: 33px;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 2px;
  margin-top: 6px;
}
.static .banner-right p {
  margin-top: 6px;
  letter-spacing: 2px;
  font-weight: 250;
}
.static .banner-right h1,
.static .banner-right h2,
.static .banner-right p {
  color: #ffffff;
}
.static .banner,
.static .banner-part {
  background-repeat: no-repeat;
  background-position: center top;
}
.static .btn-banner {
  margin-top: 45px;
}
.static .btn-to {
  margin-left: 20px;
  padding: 16px 48px;
  font-size: 18px;
  font-weight: 550;
  letter-spacing: 0.4px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333333;
}
.static .btn-to:first-child {
  margin: 0;
  color: #fff;
  background-color: #ff5b18;
}
.static .btn-to:hover {
  background-color: #ffd2a4;
}
.static .detail {
  min-width: 1220px;
  overflow: hidden;
}
.static .detail-wrap {
  width: 1200px;
  height: 720px;
  position: relative;
}
.static .detail .detail-wrap img,
.static .detail-img-doc,
.static .ic-01-hide,
.static .detail-des {
  position: absolute;
}
.static .detail .detail-wrap .detail-des {
  width: 416px;
  top: 125px;
}
.static .detail-img-doc {
  top: 130px;
  left: 75px;
  width: 660px;
  height: 464px;
}
.static .ic-01-cone {
  top: -73px;
  left: -112px;
  z-index: 99;
}
.static .ic-01-part {
  top: 0;
  width: 660px;
  height: 464px;
}
.static .ic-01-hide {
  overflow: hidden;
  top: -136px;
  width: 660px;
  height: 600px;
}
.static .ic-01-red {
  top: 453px;
  left: 19px;
}
.static .ic-01-yellow {
  top: 389px;
  left: 168px;
}
.static .ic-01-green {
  top: 464px;
  left: 318px;
}
.static .ic-01-blue {
  top: 438px;
  right: 57px;
}
.static .detail-doc {
  top: 244px;
  right: 0;
}
.static .detail-wrap h2 {
  font-size: 44px;
  font-weight: 550;
  color: #333333;
  padding-bottom: 20px;
  line-height: 62px;
  width: 450px;
}
.static .detail-wrap h2 span {
  color: #ff5b18;
}
.static .detail-wrap p {
  font-size: 16px;
  line-height: 28px;
  text-align: justify;
  color: #666666;
}
.static .wrap-b {
  position: relative;
  background-color: #f5f7fa;
}
.static .detail .detail-wrap .detail-collect {
  top: 278px;
  left: 50px;
}
.static .wrap-b .ic-02-cubes {
  position: absolute;
  height: 227px;
  width: 235px;
  top: 592px;
  right: 12px;
  z-index: 10;
}
.static .detail-img-collect {
  position: absolute;
  top: 188px;
  right: 0;
  height: 635px;
  width: 650px;
}
.static .detail-img-collect .ic-02-cube {
  height: 426px;
}
.static .detail-img-collect .ic-02-phone {
  width: 700px;
  top: -50px;
  right: 0;
}
.static .detail-img-collect .ic-02-logo {
  top: 130px;
  right: -30px;
}
.static .detail-img-collect .ic-02-blue {
  top: 210px;
  right: -2px;
}
.static .detail .wrap-ocr,
.static .detail .wrap-share {
  height: 780px;
}
.static .detail .detail-wrap .detail-ocr {
  top: 291px;
  right: 0;
}
.static .detail-img-ocr {
  position: absolute;
  height: 591px;
  width: 591px;
  top: 118px;
  left: 0;
}
.static .detail .detail-wrap .detail-img-ocr .ic-03-wave {
  top: 0;
  width: 775px;
}
.static .detail .detail-wrap .detail-img-ocr .ic-03-blue {
  top: 236px;
  left: 228px;
}
.static .detail .detail-wrap .detail-img-ocr .scanbar-f {
  width: 216px;
  height: 340px;
  overflow: hidden;
  position: absolute;
  top: 55px;
  left: -80px;
}
.static .detail .detail-wrap .detail-img-ocr .scanbar-f .ic-03-scanbar {
  width: 216px;
  height: 31px;
  top: 80px;
  animation: scanner 5s linear 2s 1 normal;
}
.static .detail .detail-wrap .detail-img-ocr .ic-03-page-1 {
  width: 350px;
  height: 586px;
  top: -53px;
  left: -161px;
}
.static .detail .detail-wrap .detail-img-ocr .ic-03-page-2 {
  width: 270px;
  height: 522px;
  top: 42px;
  left: 79px;
}
.static .detail .wrap-b .ic-04-cube {
  position: absolute;
  width: 168px;
  height: 174px;
  top: -72px;
  left: 22px;
  z-index: 10;
}
.static .detail .wrap-b .ic-04-cube {
  position: absolute;
  width: 168px;
  height: 174px;
  top: -72px;
  left: 22px;
  z-index: 10;
}
.static .detail .wrap-b .ic-04-cone {
  position: absolute;
  right: 14px;
  top: 638px;
  z-index: 10;
}
.static .detail .detail-wrap .detail-img-sides {
  position: absolute;
  height: 472px;
  width: 775px;
  top: 156px;
  right: -50px;
}
.static .detail .detail-wrap .detail-img-sides .ic-04-computer {
  height: 472px;
  width: 775px;
  top: 0;
}
.static .detail .detail-wrap .detail-img-sides .ic-04-iphone {
  width: 159px;
  height: 302px;
  z-index: 2;
  left: 40px;
  top: 150px;
}
.static .detail .detail-wrap .detail-img-sides .ic-04-android {
  width: 176px;
  height: 322px;
  z-index: 1;
  left: 80px;
  top: 130px;
}
.static .detail .detail-wrap .detail-img-sides .ic-04-sync {
  width: 129px;
  height: 129px;
  right: 37px;
  top: 313px;
}
.static .detail .detail-wrap .detail-img-sides .ic-04-sync-shadow {
  left: 571px;
  top: 279px;
}
.static .detail .detail-wrap .detail-img-sides .ic-04-ipad {
  width: 299px;
  height: 426px;
  right: 50px;
  top: 44px;
}
.static .technology {
  height: 120px;
  margin-bottom: 80px;
}
.static .technology .security-link {
  margin: 0 auto;
  padding: 18px 0;
}
.static .technology a {
  display: inline-block;
  margin-right: 200px;
}
.static .technology a:last-child {
  margin-right: 0;
}
.static .technology a span {
  color: #7c808c;
}
.static .technology img {
  height: 80px;
  margin-right: 10px;
}
.static .technology .wrap-text {
  display: inline-block;
}
.static .technology .wrap-text p {
  display: flex;
  flex-direction: column;
}
.static .security {
  height: 300px;
}
.static .security-title {
  font-size: 40px;
  font-weight: 500;
  text-align: center;
}
.static .security-line {
  width: 48px;
  height: 4px;
  margin: 20px auto;
  background-color: #ff5b18;
  border-radius: 4px;
}
.static .security-link {
  width: 1200px;
  text-align: center;
}
.static .security .security-link a {
  display: inline-block;
  height: 80px;
  margin-right: 200px;
}
.static .security .security-link a img {
  width: 210px;
}
.static .security .security-link a:last-of-type {
  margin-right: 0;
}
.static #fast-signin {
  width: 1200px;
  margin: 0 auto;
  padding: 4.5rem 3.5rem;
  text-align: center;
  background-image: linear-gradient(135deg, #ff5b18 0%, #ff5B88 100%);
  border-radius: 20px;
}
.static #fast-signin h2 {
  font-size: 2.25rem;
  color: #fff;
  padding: 0 5rem;
  margin-bottom: 3rem;
}
.static #fast-signin .btn-login {
  display: inline-flex;
  align-items: center;
  padding: 0 1rem;
  height: 2.375rem;
  font-size: 0.875rem;
  color: #ff5b18;
  background-color: #fff;
  border: none;
  border-radius: 2px;
}
.static #fast-signin .btn-login:hover {
  border-radius: 0;
  color: #fff;
  background-color: #313653;
}
.static footer.ft-static {
  margin-top: 1rem;
  background-color: #383b48;
  margin-bottom: 0;
  color: #a8abb3;
}
.static footer .ft-about-links {
  text-align: center;
  height: 20px;
  padding-top: 23px;
  margin: 0 auto;
}
.static footer .ft-about-links li {
  display: inline-block;
  margin: 0 7px;
}
.static .ft-about-links li a {
  font-size: 14px;
  color: #a8abb3;
}
.static .ft-about-links li a:hover {
  color: #ffffff;
}
.static .ft-line {
  width: 1200px;
  height: 0;
  border-bottom: 1px solid #ffffff;
  opacity: .1;
  padding-top: 32px;
}
.static .copyright {
  text-align: center;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  opacity: .6;
  margin: 0 auto;
}
