.login{
  // height: 100vh;
  .page-inner {
    position: relative;
    max-width: 1200px;
    background: #fff;
    margin: 0 auto;
    // overflow-x: hidden;
    .gtco-nav {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      padding: 20px 0;
      z-index: 1001;
      .gtco-logo {    
        margin: 0;
        padding: 0;
      }
      a {
        font-size: 28px;
        font-weight: bold;
        padding: 5px 10px;
        color: #fff;
      }
    }
    .gtco-header {
      background-image: url('./imgs/login_background.jpg');
    }
    .gtco-cover {
      height: 900px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      position: relative;
      z-index: 100;
      width: 100%;
      &:after {
        display: block;
        content: '';
        clear: both;
      };
      .overlay {
        z-index: 1;
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.5);
      }
      .gtco-container {
        .container-wrapper {
          padding-top: 15rem;
          .intro-text-small, h1 {
            position: relative;
            z-index: 12;
          }
          .intro-text-small {
            font-size: 14px;
            color: rgba(255, 255, 255, .5);
            letter-spacing: .1em;
          }
          h1 {
            font-size: 59px;
            line-height: 1;
            font-weight: 300;
            margin-top: 10px;
            color: #fff;
          }
          .login-form{
            position: relative;
            z-index: 12;
            width: 380px;
            min-height: auto;
            max-height: 600px;
            overflow-y: auto;
            padding: 36px;
            box-shadow: 0 0 100px rgba(0,0,0,.08);
            background: #fff;
            .login-logo{
              text-align: center;
              height: 40px;
              line-height: 40px;
              cursor: pointer;
              margin-bottom: 20px;
              span {
                vertical-align: text-bottom;
                font-size: 24px;
                text-transform: uppercase;
                display: inline-block;
              }
            }
            .ant-tabs-nav {
              width: 100%;
            }
            .ant-tabs-nav div {
              text-align: center;
            }
            .ant-tabs-tab {
              width: 43%;
              padding: 0 ;
              text-align: center;
            }
            .code-prompt {
              color: red
            }
          }
        }
      }
    }
    .gtco-footer {
      padding-top: 3em;
      padding-left: 15px;
      padding-bottom: .2em;
      .gtco-container {
        max-width: 1100px;
        margin: 0 auto;
        .footer-desc {
          padding-left: 10px;
          padding-right: 10px;
        }
        .gtco-widget {
          margin-bottom: 10px;
          h3 {
            font-weight: bold;
            font-size: 15px;
            letter-spacing: 2px;
            text-transform: uppercase;
          }
          p, li, a{
            font-size: 16px;
            font-weight: 300;
            line-height: 1.7;
            color: #777;
          }
          ul {
            padding: 0;
            li {
              margin-bottom: 10px;
              svg {
                margin-right: 10px;
              }
            }
          }
          a {
            color: #777;
          }
        }
        .copyright {
          margin-top: 1em;
          padding-top: 1em;
          padding-left: 10px;
          padding-right: 10px;
          border-top: 1px solid #f2f2f2;
          .block {
            font-size: 85%;
            line-height: 1.7;
            color: #777;
          }
        }
      }
    }
  }
}
.go-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  opacity: .8;
  // visibility: hidden;
  transition: .5s;
  a {
    width: 50px;
    height: 50px;
    display: table;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
  }
  i {
    height: 50px;
    display: table-cell;
    vertical-align: middle;
  }
}
.installer {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100px 100px 0;
  border-color: transparent #313653 transparent transparent;
  position: fixed;
  top: 0;
  right: 0;
}
.installer__btn {
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
  left: 50px;
  position: absolute;
  background: url('./imgs/installer.png') no-repeat;
  background-size: cover;
}
