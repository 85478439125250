.header .left {
  float: left;
  display: flex;
  height: 100%;
  align-items: flex-end;
}
.header .left div {
  height: 46px;
  display: inline-block;
  line-height: 46px;
  padding: 0 30px;
}
.header .left div a {
  color: #AAADBB;
}
.header .left div a i {
  margin-right: 5px;
}
.header .left .route {
  border-top: 4px solid #ff5b18;
  background: #fff;
}
.header .left .route a {
  color: #666;
}
.header .right {
  float: right;
  line-height: 55px;
  border-bottom: none;
}
